@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@font-face {
  font-family: "Regular";
  /*Can be any text*/
  src: local("allroundgothic-demi"), url("../fonts/AllRoundGothic-Demi.ttf") format("truetype");
}
/*
Colors
*/
/* Onboarding questions */
.question-section {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  font-family: "IBM Plex Sans" !important;
  margin-top: 20px;
  margin-bottom: 40px;
}
.question-section button {
  border: solid;
  border-width: 2px;
  color: #5EB1BF;
  background-color: #FDFDFD !important;
}
.question-section button:hover {
  background-color: #5EB1BF !important;
  color: #FDFDFD;
  border: solid;
}
.question-section .section-title {
  font-family: "IBM Plex Sans" !important;
  color: #484848 !important;
  font-size: 2rem !important;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
}
.question-section .question-section-confirm {
  width: 120px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}
.question-section .question-section-confirm button {
  border: solid;
  border-width: 2px;
  color: #5EB1BF;
  background-color: #FDFDFD !important;
}
.question-section .question-section-confirm button:hover {
  background-color: #5EB1BF !important;
  color: #FDFDFD;
}

.component-disabled {
  opacity: 0.2;
  pointer-events: none;
}

.book-qoute-btns {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.book-qoute-btns button {
  margin-top: 20px;
  margin-bottom: 25px;
}

.ui-datepicker {
  font-family: "IBM Plex Sans" !important;
  width: 100% !important;
  background-color: #FDFDFD;
}
.ui-datepicker .ui-datepicker-header {
  background-color: #FDFDFD !important;
  color: #484848 !important;
}
.ui-datepicker .ui-datepicker-year {
  display: none;
}
.ui-datepicker .ui-state-default {
  background-color: #FDFDFD;
  color: #484848;
  border: 1px solid #484848;
}
.ui-datepicker .ui-state-highlight {
  background-color: #CBEAF0;
  border-color: #5EB1BF;
}
.ui-datepicker .ui-state-disabled span {
  background-color: #b1b0b0;
  color: #303030 !important;
  border: 1px solid #303030;
}
.ui-datepicker .ui-datepicker-today .ui-state-default {
  background-color: #FFF8C4 !important;
  color: #5B5B5B !important;
}
.ui-datepicker td {
  padding: 5px !important;
}
.ui-datepicker td .ui-state-active {
  background-color: #CBEAF0;
  border-color: #5EB1BF;
  color: #484848;
}
.ui-datepicker td a, .ui-datepicker td span {
  padding: 20px !important;
  border-radius: 10px;
  text-align: center;
  font-weight: 600 !important;
}
.casper-option-select-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  height: 120px;
}
.casper-option-select-container .casper-option-card {
  border-style: solid;
  border-width: 1px;
  margin: 10px;
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  height: 100px;
}

.casper-option-card-selected {
  border: none !important;
  box-shadow: 0px 0px 0px 5px #5EB1BF;
  -webkit-box-shadow: 0px 0px 0px 5px #5EB1BF;
  -moz-box-shadow: 0px 0px 0px 5px #5EB1BF;
}

.helpful-tip {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

.question-section h1 {
  color: #5EB1BF;
}
.question-section h2 {
  color: #5EB1BF !important;
}

/* Get a qoute page */
.casper-getaqoute-page .scroll-menu {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.casper-getaqoute-page .casper-box-shadow {
  box-shadow: 18px 16px 0px 2px #CBEAF0;
  -webkit-box-shadow: 18px 16px 0px 2px #CBEAF0;
  -moz-box-shadow: 18px 16px 0px 2px #CBEAF0;
  color: #CBEAF0;
  border-style: solid;
  border-width: 3px;
  border-radius: 3px;
  margin-bottom: 30px;
}
.casper-getaqoute-page .selected-storage-unit-container {
  color: #CBEAF0;
  border-style: solid;
  border-width: 3px;
  border-radius: 10px;
  width: 50%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-around;
  background-color: white;
}
.casper-getaqoute-page .selected-storage-unit-container .selected-storage-unit {
  display: flex;
}
.casper-getaqoute-page .selected-storage-unit-container .selected-storage-unit .storage-video img {
  width: 300px;
  margin: 20px;
}
.casper-getaqoute-page .selected-storage-unit-container .selected-storage-unit .storage-description {
  width: auto;
  margin: 20px;
  position: relative;
  color: #484848;
}
.casper-getaqoute-page .selected-storage-unit-container .selected-storage-unit .storage-description h3 {
  font-family: "IBM Plex Sans" !important;
  text-align: center;
}
.casper-getaqoute-page .selected-storage-unit-container .selected-storage-unit .storage-description h4 {
  font-family: "IBM Plex Sans" !important;
  font-size: 0.8rem;
  text-align: center;
}
.casper-getaqoute-page .selected-storage-unit-container .selected-storage-unit .storage-description h1 {
  font-family: "Ubuntu" !important;
  position: absolute;
  bottom: 0px;
  text-align: right;
  width: 100%;
}
.casper-getaqoute-page .selected-storage-unit-container .selected-storage-unit .storage-description p {
  font-size: 0.8rem;
}
.casper-getaqoute-page .unit-select {
  overflow: auto;
  white-space: nowrap;
}
.casper-getaqoute-page .unit-select .selected-unit {
  background-color: #5EB1BF;
}
.casper-getaqoute-page .unit-select .selected-unit h2 {
  color: #FDFDFD !important;
}
.casper-getaqoute-page .unit-select .unit {
  display: inline-block;
  color: white;
  text-align: center;
  text-decoration: none;
  width: 120px;
  padding: 14px;
  margin-right: -2px;
  border-style: solid;
  border-width: 2px;
  border-color: #5EB1BF;
}
.casper-getaqoute-page .unit-select .unit h2 {
  margin-bottom: 0px;
}
.casper-getaqoute-page .yourplan-card {
  margin-left: auto;
  margin-right: auto;
  border: solid 4px #CBEAF0;
  width: 40%;
}
.casper-getaqoute-page .yourplan-card .yourplan-card-title {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #CBEAF0;
  color: #484848;
}
.casper-getaqoute-page .yourplan-card .yourplan-card-title h3 {
  text-align: center;
  font-weight: 300 !important;
  font-size: 20px;
}
.casper-getaqoute-page .yourplan-card .yourplan-card-body {
  text-align: left;
  padding: 8px;
}
.casper-getaqoute-page .yourplan-card .yourplan-card-body h3 {
  font-weight: 300;
}
.casper-getaqoute-page .yourplan-card .yourplan-card-body h1 {
  text-align: right;
}
.casper-getaqoute-page .day-select-section {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  font-family: "IBM Plex Sans";
}
.casper-getaqoute-page .day-select-section .day-select-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.casper-getaqoute-page .day-select-section .day-select-cards .day-option {
  border-style: solid;
  display: flex;
  justify-content: space-between;
  border-width: 1px;
  border-radius: 10px;
  padding: 15px;
  margin: 10px;
  min-width: 200px;
  width: 200px;
}
.casper-getaqoute-page .day-select-section .day-select-cards .day-option h2 {
  color: #484848 !important;
  margin-bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 400;
}
.casper-getaqoute-page .day-select-section .day-select-cards .day-option h3 {
  color: #5EB1BF;
  margin-bottom: 0px;
  text-align: right;
}

.question-section .info {
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}
.question-section .user-info {
  display: flex;
  flex-wrap: wrap;
}
.question-section .user-info .input-item {
  width: 100%;
  margin-top: 10px;
}
.question-section .user-info input, .question-section .user-info label {
  display: block;
}
.question-section .user-info input {
  width: 100% !important;
  padding: 10px;
}
.question-section .address-container {
  margin-left: 30px;
  margin-right: 30px;
}
.question-section .address-container .address-input {
  width: 100%;
  padding: 10px;
}

.storable-nav {
  font-family: "IBM Plex Sans" !important;
  background-color: #5EB1BF !important;
}
.storable-nav .storable-btn {
  background-color: #FDFDFD !important;
  color: #5EB1BF;
  font-weight: 500;
  border: none;
}
.storable-nav .storable-btn:hover {
  background-color: #cccccc !important;
}
.storable-nav .storable-btn:active {
  color: #5EB1BF;
}
.storable-nav .storable-dropdown-divider {
  background-color: #e6e4e4 !important;
  pointer-events: none;
}
.storable-nav .storable-dropdown-divider:hover {
  background-color: #e6e4e4 !important;
  pointer-events: none;
}
.storable-nav .navbar-brand h1 {
  color: #F7F7F7;
  margin-top: 0.8rem;
  padding-bottom: 1rem;
}
.storable-nav .navbar-brand img {
  width: 14rem;
}
.storable-nav h1 {
  font-family: "Regular" !important;
}

/*
Colors
*/
/* Onboarding questions */
.question-section {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  font-family: "IBM Plex Sans" !important;
  margin-top: 20px;
  margin-bottom: 40px;
}
.question-section button {
  border: solid;
  border-width: 2px;
  color: #5EB1BF;
  background-color: #FDFDFD !important;
}
.question-section button:hover {
  background-color: #5EB1BF !important;
  color: #FDFDFD;
  border: solid;
}
.question-section .section-title {
  font-family: "IBM Plex Sans" !important;
  color: #484848 !important;
  font-size: 2rem !important;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
}
.question-section .question-section-confirm {
  width: 120px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}
.question-section .question-section-confirm button {
  border: solid;
  border-width: 2px;
  color: #5EB1BF;
  background-color: #FDFDFD !important;
}
.question-section .question-section-confirm button:hover {
  background-color: #5EB1BF !important;
  color: #FDFDFD;
}

.component-disabled {
  opacity: 0.2;
  pointer-events: none;
}

.book-qoute-btns {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.book-qoute-btns button {
  margin-top: 20px;
  margin-bottom: 25px;
}

.ui-datepicker {
  font-family: "IBM Plex Sans" !important;
  width: 100% !important;
  background-color: #FDFDFD;
}
.ui-datepicker .ui-datepicker-header {
  background-color: #FDFDFD !important;
  color: #484848 !important;
}
.ui-datepicker .ui-datepicker-year {
  display: none;
}
.ui-datepicker .ui-state-default {
  background-color: #FDFDFD;
  color: #484848;
  border: 1px solid #484848;
}
.ui-datepicker .ui-state-highlight {
  background-color: #CBEAF0;
  border-color: #5EB1BF;
}
.ui-datepicker .ui-state-disabled span {
  background-color: #b1b0b0;
  color: #303030 !important;
  border: 1px solid #303030;
}
.ui-datepicker .ui-datepicker-today .ui-state-default {
  background-color: #FFF8C4 !important;
  color: #5B5B5B !important;
}
.ui-datepicker td {
  padding: 5px !important;
}
.ui-datepicker td .ui-state-active {
  background-color: #CBEAF0;
  border-color: #5EB1BF;
  color: #484848;
}
.ui-datepicker td a, .ui-datepicker td span {
  padding: 20px !important;
  border-radius: 10px;
  text-align: center;
  font-weight: 600 !important;
}
.casper-option-select-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  height: 120px;
}
.casper-option-select-container .casper-option-card {
  border-style: solid;
  border-width: 1px;
  margin: 10px;
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  height: 100px;
}

.casper-option-card-selected {
  border: none !important;
  box-shadow: 0px 0px 0px 5px #5EB1BF;
  -webkit-box-shadow: 0px 0px 0px 5px #5EB1BF;
  -moz-box-shadow: 0px 0px 0px 5px #5EB1BF;
}

.helpful-tip {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

.question-section h1 {
  color: #5EB1BF;
}
.question-section h2 {
  color: #5EB1BF !important;
}

/* Get a qoute page */
.casper-getaqoute-page .scroll-menu {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.casper-getaqoute-page .casper-box-shadow {
  box-shadow: 18px 16px 0px 2px #CBEAF0;
  -webkit-box-shadow: 18px 16px 0px 2px #CBEAF0;
  -moz-box-shadow: 18px 16px 0px 2px #CBEAF0;
  color: #CBEAF0;
  border-style: solid;
  border-width: 3px;
  border-radius: 3px;
  margin-bottom: 30px;
}
.casper-getaqoute-page .selected-storage-unit-container {
  color: #CBEAF0;
  border-style: solid;
  border-width: 3px;
  border-radius: 10px;
  width: 50%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-around;
  background-color: white;
}
.casper-getaqoute-page .selected-storage-unit-container .selected-storage-unit {
  display: flex;
}
.casper-getaqoute-page .selected-storage-unit-container .selected-storage-unit .storage-video img {
  width: 300px;
  margin: 20px;
}
.casper-getaqoute-page .selected-storage-unit-container .selected-storage-unit .storage-description {
  width: auto;
  margin: 20px;
  position: relative;
  color: #484848;
}
.casper-getaqoute-page .selected-storage-unit-container .selected-storage-unit .storage-description h3 {
  font-family: "IBM Plex Sans" !important;
  text-align: center;
}
.casper-getaqoute-page .selected-storage-unit-container .selected-storage-unit .storage-description h4 {
  font-family: "IBM Plex Sans" !important;
  font-size: 0.8rem;
  text-align: center;
}
.casper-getaqoute-page .selected-storage-unit-container .selected-storage-unit .storage-description h1 {
  font-family: "Ubuntu" !important;
  position: absolute;
  bottom: 0px;
  text-align: right;
  width: 100%;
}
.casper-getaqoute-page .selected-storage-unit-container .selected-storage-unit .storage-description p {
  font-size: 0.8rem;
}
.casper-getaqoute-page .unit-select {
  overflow: auto;
  white-space: nowrap;
}
.casper-getaqoute-page .unit-select .selected-unit {
  background-color: #5EB1BF;
}
.casper-getaqoute-page .unit-select .selected-unit h2 {
  color: #FDFDFD !important;
}
.casper-getaqoute-page .unit-select .unit {
  display: inline-block;
  color: white;
  text-align: center;
  text-decoration: none;
  width: 120px;
  padding: 14px;
  margin-right: -2px;
  border-style: solid;
  border-width: 2px;
  border-color: #5EB1BF;
}
.casper-getaqoute-page .unit-select .unit h2 {
  margin-bottom: 0px;
}
.casper-getaqoute-page .yourplan-card {
  margin-left: auto;
  margin-right: auto;
  border: solid 4px #CBEAF0;
  width: 40%;
}
.casper-getaqoute-page .yourplan-card .yourplan-card-title {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #CBEAF0;
  color: #484848;
}
.casper-getaqoute-page .yourplan-card .yourplan-card-title h3 {
  text-align: center;
  font-weight: 300 !important;
  font-size: 20px;
}
.casper-getaqoute-page .yourplan-card .yourplan-card-body {
  text-align: left;
  padding: 8px;
}
.casper-getaqoute-page .yourplan-card .yourplan-card-body h3 {
  font-weight: 300;
}
.casper-getaqoute-page .yourplan-card .yourplan-card-body h1 {
  text-align: right;
}
.casper-getaqoute-page .day-select-section {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  font-family: "IBM Plex Sans";
}
.casper-getaqoute-page .day-select-section .day-select-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.casper-getaqoute-page .day-select-section .day-select-cards .day-option {
  border-style: solid;
  display: flex;
  justify-content: space-between;
  border-width: 1px;
  border-radius: 10px;
  padding: 15px;
  margin: 10px;
  min-width: 200px;
  width: 200px;
}
.casper-getaqoute-page .day-select-section .day-select-cards .day-option h2 {
  color: #484848 !important;
  margin-bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 400;
}
.casper-getaqoute-page .day-select-section .day-select-cards .day-option h3 {
  color: #5EB1BF;
  margin-bottom: 0px;
  text-align: right;
}

.question-section .info {
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}
.question-section .user-info {
  display: flex;
  flex-wrap: wrap;
}
.question-section .user-info .input-item {
  width: 100%;
  margin-top: 10px;
}
.question-section .user-info input, .question-section .user-info label {
  display: block;
}
.question-section .user-info input {
  width: 100% !important;
  padding: 10px;
}
.question-section .address-container {
  margin-left: 30px;
  margin-right: 30px;
}
.question-section .address-container .address-input {
  width: 100%;
  padding: 10px;
}

/*
Colors
*/
/*************/
/* Site Wide */
/*************/
body {
  background-color: #f7f7f7 !important;
  font-family: "Ubuntu" !important;
}

h1 {
  font-family: "Regular" !important;
  font-size: 3rem;
  color: #73af7e;
  text-align: center;
  margin-bottom: 0px;
}

h2 {
  color: #73af7e !important;
  font-size: 1rem !important;
}

.checkout-list {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-color: white;
  margin-top: 2rem;
}
.checkout-list .checkout-list-item {
  display: flex;
  margin: 1rem;
  padding: 1rem;
}
.checkout-list .checkout-list-item .checkout-list-text {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}
.checkout-list img {
  width: 20rem;
  border-radius: 10px;
}
.checkout-list .checkout-list-controls {
  display: flex;
  justify-content: center;
}
.checkout-list .checkout-list-controls button {
  background-color: #73af7e;
  border: none;
  padding: 5px;
  margin: 5px;
}
.checkout-list .checkout-list-controls .storable-btn-secondary {
  background: #A2D1AB !important;
}
.checkout-list .checkout-list-controls .storable-btn-secondary a {
  color: #F7F7F7;
}

/* Onboarding Form */
.casper-onboard-page {
  height: 100%;
}
.casper-onboard-page .branding {
  text-align: center;
  margin-top: 20px;
}
.casper-onboard-page .branding img {
  height: 150px;
}
.casper-onboard-page .form-content {
  display: flex;
  width: 50vw;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.casper-onboard-page .form-content .address-container {
  max-width: 100%;
}
.casper-onboard-page .form-content .address-container label {
  display: block;
}
.casper-onboard-page .form-content input {
  padding: 12px;
  border-radius: 8px;
  border-color: rgb(237, 236, 236) !important;
  border: solid 1px;
}
.casper-onboard-page .form-content .date-select input {
  width: 70%;
  margin: 0px;
}
.casper-onboard-page .form-content .date-select select {
  padding: 15px;
  border-radius: 8px;
  border-color: #e4e4e4 !important;
  width: 30%;
  margin: 0px;
}
.casper-onboard-page .form-content .address-input {
  width: 500px;
  max-width: 100%;
}
.casper-onboard-page .form-content .date-select-container {
  margin-top: 15px;
}
.casper-onboard-page .Casper-form-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 12vh;
  background-color: white;
  border-top: 8px solid #73af7e;
}
.casper-onboard-page .Casper-form-footer .controls {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 12vh;
  align-items: center;
  justify-content: end;
}
.casper-onboard-page .Casper-form-footer .controls button {
  width: 100px;
  margin-right: 10px;
  border-radius: 5px;
}
.casper-onboard-page .form-toolbar {
  margin: 20px;
  display: flex;
  justify-content: center;
}
.casper-onboard-page .form-toolbar .page {
  width: 100px;
  margin: 20px;
}
.casper-onboard-page .form-toolbar .page .toolbar-dash hr {
  background-color: grey;
  color: grey;
  height: 10px;
}

.plan-additions {
  margin-bottom: 12vh;
}

.confirm-container {
  display: flex;
}
.confirm-container h1 {
  font-size: 2rem;
  text-align: left;
}
.confirm-container .plan {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dcdcdc;
  text-align: center;
  padding: 12px 30px 12px 30px;
  margin: 10px;
  border-radius: 8px;
}
.confirm-container .plan .price h3 {
  margin: 0px;
  font-weight: 400;
  font-size: 15px;
}
.confirm-container .plan .price h2 {
  margin-bottom: 0px;
}
.confirm-container .plan .price p {
  font-size: 10px;
  margin: 0px;
  margin-left: 5px;
}

.plan-container {
  display: flex;
  flex-wrap: wrap;
}
.plan-container .plan {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dcdcdc;
  text-align: center;
  padding: 12px 30px 12px 30px;
  margin: 10px;
  border-radius: 8px;
}
.plan-container .plan .price h3 {
  margin: 0px;
  font-weight: 400;
  font-size: 15px;
}
.plan-container .plan .price h2 {
  margin-bottom: 0px;
}
.plan-container .plan .price p {
  font-size: 10px;
  margin: 0px;
  margin-left: 5px;
}
.plan-container .plan h3 {
  font-size: 1rem;
  margin: 0px;
}

.create-acc-details {
  margin-left: 15px;
  margin-right: 15px;
}
.create-acc-details input {
  width: 100%;
}

.booking-card button {
  margin-top: 10px;
}

.onboard-wrapper {
  background-image: url("../images/storable-background.svg");
  background-size: cover;
}

.onboard-form {
  margin-top: 20px;
  height: 80%;
}

.casper-btn-secondary {
  background-color: #A2D1AB !important;
}

.storable-btn:hover {
  background-color: rgba(115, 175, 126, 0.9137254902) !important;
}

.selected {
  border: solid 2px #5EB1BF !important;
  border-radius: 6px 6px 6px 6px !important;
}

.selected-icon {
  display: relative !important;
}

.select-icon {
  display: none;
}

.check-mark {
  width: 20px;
  position: absolute;
  bottom: 20px;
  right: 10px;
}

.item-toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.item-toolbar .input-group {
  margin-top: 20px;
}
.item-toolbar .item-select-radio {
  margin-left: 15px;
}

/*modal*/
.modal-dialog .modal-body {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.modal-dialog .modal-body .modal-item-title {
  justify-content: center;
}
.modal-dialog .modal-body .storable-img-badge {
  width: 50% !important;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  margin-top: 0;
  max-height: 50%;
}
.modal-dialog .modal-body .modal-img-container {
  width: 50%;
}
.modal-dialog .modal-body .modal-img-container img {
  border-radius: 10px;
}

/* Welcome Banner */
.storable-welcome-banner {
  background-color: #F7F7F7;
  margin: 0;
  padding-top: 0.8rem;
  padding-bottom: 0.5rem;
  padding: 1rem;
  border-bottom: rgba(59, 58, 58, 0.062745098) solid;
}
.storable-welcome-banner h5 {
  font-family: "IBM Plex Sans" !important;
  font-weight: 400;
}

/* Onboarding */
.page-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.onboard-form-container h1 {
  font-family: "Ubuntu", sans-serif !important;
  margin-bottom: 10px;
}

.storable-onboard-card {
  margin-top: auto;
  height: 100vh;
  margin-bottom: auto;
}

.form-btn-main {
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #73af7e;
  border: none;
  color: #F7F7F7;
  margin-right: 10px;
  margin-bottom: 15px;
}

.onboard-address-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.onboard-address-container .address-form {
  width: 30rem;
}

.address-form div {
  margin-top: 10px;
}

.onboard-form {
  width: 100%;
}

.casper-hero-container {
  width: 45%;
  text-align: center;
}
.casper-hero-container .casper-hero-img {
  width: 80%;
}
.casper-hero-container h1 {
  color: #757776;
  font-family: "PT Sans", sans-serif !important;
  margin-top: 15px;
  font-size: 3rem;
}
.casper-hero-container h1 .span {
  color: #73af7e;
  font-weight: bold;
}

/* End of onboarding */
/* user details */
.userdetails {
  justify-content: center;
  margin-top: 1rem;
}
.userdetails h2 {
  text-align: center;
  color: #3B3A3A !important;
  margin-bottom: 1.5rem;
}
.userdetails div {
  width: 32rem;
}
.userdetails div .storable-btn {
  background-color: #73af7e !important;
  color: #F7F7F7;
  border: none;
  width: 100%;
  height: 3rem;
}
.userdetails div storable-btn:hover {
  background-color: #A2D1AB !important;
}

.onboarded-havingfun-container {
  display: flex;
  justify-content: center;
}

.onboarded-havingfun {
  text-align: center;
}
.onboarded-havingfun h1 {
  margin-top: 15px;
}
.onboarded-havingfun img {
  margin-top: 25px;
}
.onboarded-havingfun p {
  margin-top: 25px;
  margin-bottom: 25px;
}

/* Inventory */
.storable-inventory {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 100px;
}
.storable-inventory h3 {
  font-family: "IBM Plex Sans" !important;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  font-weight: 400;
}
.storable-inventory .storable-card {
  border-radius: 4px;
  border: none;
  width: 250px;
  margin: 10px;
  justify-content: left;
  box-shadow: 0px 0px 17px -2px rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0px 0px 17px -2px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0px 0px 17px -2px rgba(0, 0, 0, 0.19);
}
.storable-inventory .storable-card .card-body p {
  margin-bottom: 0px;
}
.storable-inventory .storable-card .storable-img-container {
  position: relative;
  border-radius: 4px;
  border: none;
}
.storable-inventory .storable-card .storable-img-container .storable-card-img {
  border: none;
}
.storable-inventory .storable-card .storable-img-container .storable-img-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: green;
  color: #F7F7F7;
  padding: 2px 10px;
  border-radius: 3px;
}
.storable-inventory .storable-card .storable-img-container .unknown {
  background-color: #dc3545;
}
.storable-inventory .storable-card .storable-img-container .en-route {
  background-color: #ffc107;
}
.storable-inventory .storable-card .storable-img-container .stored {
  background-color: #7DB48C;
}
.storable-inventory .storable-card .storable-img-container .pending {
  background-color: #A2D1AB;
}
.storable-inventory .storable-card .storable-img-container .returned {
  background-color: #17a2b8;
}
.storable-inventory .storable-card .storable-img-container p {
  font-family: "IBM Plex Sans" !important;
  margin-bottom: 0px;
}

.storable-img-badge {
  top: 10px;
  left: 10px;
  color: #F7F7F7;
  padding: 2px 10px;
  border-radius: 3px;
  text-align: center;
}

.unknown {
  background-color: #dc3545;
}

.en-route {
  background-color: #ffc107;
}

.stored {
  background-color: #5EB1BF !important;
}

.pending {
  background-color: #A2D1AB;
}

.returned {
  background-color: #17a2b8;
}

p {
  font-family: "IBM Plex Sans" !important;
  margin-bottom: 0px;
}

.storable-background-wrapper {
  background-color: #5EB1BF;
  height: 100vh;
}

/* Storable Login */
.storable-login-container .storable-brand-sm-heading {
  color: #73af7e;
  text-align: center;
  margin-bottom: 1rem;
}
.storable-login-container .email-input {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.storable-login-container .email-input .form-floating {
  margin: 0.4rem;
}
.storable-login-container .storable-login-card {
  border: none !important;
  padding: 4rem;
  width: 25rem;
  height: fit-content;
  -webkit-box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.16);
}
.storable-login-container .storable-login-card h2 {
  color: #4A97A4 !important;
}
.storable-login-container .storable-login-card .storable-login-btn {
  width: 100%;
  background-color: #5EB1BF !important;
  border-color: #5EB1BF !important;
}
.storable-login-container .storable-login-card .storable-login-btn:hover {
  width: 100%;
  background-color: #73af7e !important;
  border-color: #A2D1AB !important;
}
.storable-login-container .storable-login-card .storable-login-tag {
  margin-top: 1rem;
  margin-bottom: 0;
  font-size: 0.8rem;
  color: #4A97A4;
}

.booking-card {
  border: none !important;
  padding: 2rem 2rem 1rem 2rem;
  width: auto;
  height: fit-content;
  -webkit-box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.16);
  text-align: center;
  background-color: #ffffff;
}
.booking-card .booking-header {
  font-size: 2rem;
  margin-bottom: 1rem;
}
.booking-card .storable-btn {
  margin-bottom: 1rem;
}

.account-div td {
  width: auto;
  text-align: left;
  padding-left: 1rem;
}

.badge-small {
  width: 50% !important;
}

@media screen and (max-width: 990px) {
  /* Onboarding */
  .onboard-form-container {
    display: block;
    margin-top: 20px;
  }
  .onboard-address-container {
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
  }
  .onboard-address-container .address-form {
    width: 100%;
  }
  .onboard-address-container .address-form div {
    margin-top: 10px;
  }
  .casper-hero-container {
    width: 100%;
    text-align: center;
  }
  .casper-hero-container h1 {
    color: #757776;
    font-family: "PT Sans", sans-serif !important;
    margin-top: 15px;
  }
  .casper-hero-container h1 .span {
    color: #73af7e;
    font-weight: bold;
  }
  .modal-dialog .modal-body {
    display: inline;
    text-align: center;
  }
  .modal-dialog .modal-body .modal-item-title {
    display: inline;
    text-align: center;
  }
  .modal-dialog .modal-body .storable-img-badge {
    width: 50% !important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
  }
  .modal-dialog .modal-body .modal-img-container {
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }
  .storable-inventory {
    width: 100%;
    --bs-gutter-x: 0rem;
  }
  .container {
    width: 100%;
  }
  .storable-login-card {
    width: auto;
  }
  .storable-card {
    width: 150px !important;
    margin: 0px;
  }
  .storable-nav {
    display: flex;
  }
  .storable-nav .navbar-brand img {
    width: 200px;
  }
}
.pac-item {
  padding: 10px;
}

.casper-primary-button {
  background-color: #73af7e;
  color: #F7F7F7;
}

.casper-secondary-button {
  background-color: #F7F7F7;
  color: #73af7e;
}

@media screen and (max-width: 600px) {
  .form-toolbar {
    margin-left: 10px;
    margin-right: 10px;
  }
  .confirm-container {
    margin-bottom: 12vh;
    margin-left: 15px;
    margin-right: 15px;
    display: block;
  }
  .confirm-container .details h1 {
    margin-bottom: 15px;
  }
  .confirm-container .details p {
    margin-bottom: 15px;
  }
  .confirm-container .plan-details {
    margin-top: 10px;
  }
  .casper-onboard-page .form-content {
    width: 100%;
  }
  .casper-onboard-page .address-input {
    width: 100% !important;
  }
  .casper-onboard-page .date-select-container input {
    width: 100% !important;
  }
  .casper-onboard-page .date-select-container select {
    width: 100% !important;
  }
  .item-toolbar {
    display: inline;
    align-items: center;
  }
  .item-toolbar .input-group {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .item-toolbar .item-select-radio {
    display: flex;
    justify-content: center;
  }
}
.checkout-section {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #5EB1BF;
  text-align: center;
  padding: 10px;
}
.checkout-section button {
  padding: 20px;
  height: auto;
  width: auto;
}

/* Buttons */
.storable-btn {
  background-color: #FDFDFD !important;
  color: #5EB1BF;
  width: 100%;
  height: 3rem;
  padding: 10px;
  border: none;
  width: 120px;
  border-radius: 10px;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.18);
}

.casper-btn-bs {
  width: 100%;
  background-color: #5EB1BF !important;
  border-color: #5EB1BF !important;
  max-width: 40%;
}

.logo img {
  width: 30%;
}

.casper-btn-secondary {
  background-color: #FDFDFD !important;
}

.storable-btn:hover {
  background-color: #CBEAF0 !important;
  color: #FDFDFD !important;
}

