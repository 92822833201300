@import './_variables.scss';


$casper-primary: #5EB1BF;
$casper-secondary: #CBEAF0;
$casper-black: #484848;
$casper-white: #FDFDFD;



/* Onboarding questions */
.question-section {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    font-family: 'IBM Plex Sans' !important;
    margin-top: 20px;
    margin-bottom: 40px;
    button {
      border: solid;
      border-width: 2px;
      color: $casper-primary;
      background-color: $casper-white !important;
    }
    button:hover {
      background-color: $casper-primary !important;
      color: $casper-white;
      border: solid;
    }
    .section-title {
      font-family: 'IBM Plex Sans' !important;
      color: $casper-black !important;
      font-size: 2rem !important;
      font-weight: 400;
      margin-bottom: 20px;
      text-align: center;
    }
    .question-section-confirm {
      width: 120px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;
      button {
        border: solid;
        border-width: 2px;
        color: $casper-primary;
        background-color: $casper-white !important;
      }
      button:hover {
        background-color: $casper-primary !important;
        color: $casper-white;
      }
  
    }
  }
  
//disable a section
.component-disabled {
    opacity: 0.2 ;
    pointer-events: none;
}

.book-qoute-btns {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  button {
    margin-top: 20px;
    margin-bottom: 25px;
  }

}


//Datepicker
.ui-datepicker {
  
  font-family: 'IBM Plex Sans' !important;
  width: 100% !important;
  background-color: $casper-white;

  .ui-datepicker-header {
    background-color: $casper-white !important;
    color: $casper-black !important;
  }
  

  .ui-datepicker-year {
    display: none;
  }

  .ui-state-default {
    background-color: $casper-white;
    color: $casper-black ;
    border: 1px solid $casper-black;
  }
  .ui-state-highlight {
    background-color: $casper-secondary;
    border-color: $casper-primary;

  }


  .ui-state-disabled {
    span {
      background-color: #b1b0b0;
      color: #303030 !important;
      border: 1px solid #303030;

  
    }
  }

  .ui-datepicker-today {
    .ui-state-default {
    background-color: #FFF8C4 !important;
    color: #5B5B5B !important;
    
    }
  }


  td {
    padding: 5px !important;
    .ui-state-active {
      background-color: $casper-secondary;
      border-color: $casper-primary;
      color: $casper-black;

    }

    a, span {
      padding: 20px ! important;
      border-radius: 10px;
      text-align: center;
      font-weight: 600 !important;

    }

    span {

    }
  }
}



//Select cards for storage term
.casper-option-select-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  height: 120px;
  .casper-option-card {
    border-style: solid;
    border-width: 1px;
    margin: 10px;
    width: 200px;
    padding: 10px;
    border-radius: 10px;
    height: 100px;
  }

}
//this is also used on the day option to show selected.
.casper-option-card-selected {
  border: none !important;
  box-shadow: 0px 0px 0px 5px $casper-primary;
  -webkit-box-shadow: 0px 0px 0px 5px $casper-primary;
  -moz-box-shadow: 0px 0px 0px 5px $casper-primary;
}

.helpful-tip {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}


.question-section {
  h1 {
    color: $casper-primary;
  }

  h2 {
    color: $casper-primary !important; 
  }


}



/* Get a qoute page */ 
.casper-getaqoute-page {
  .scroll-menu {
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }


  .casper-box-shadow {
    box-shadow: 18px 16px 0px 2px $casper-secondary;
    -webkit-box-shadow: 18px 16px 0px 2px $casper-secondary;
    -moz-box-shadow: 18px 16px 0px 2px $casper-secondary;
    color: $casper-secondary;
    border-style: solid;
    border-width: 3px;
    border-radius: 3px;
    margin-bottom: 30px;

  }

  .selected-storage-unit-container {
    color: $casper-secondary;
    border-style: solid;
    border-width: 3px;
    border-radius: 10px;
    width: 50%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-around;
    background-color: white;


    .selected-storage-unit {
      display: flex;
      .storage-video {
        img {
          width: 300px;
          margin: 20px;
        }
      }
      .storage-description {
        width: auto;
        margin: 20px;
        position: relative;
        color: $casper-black;

        h3 {
          font-family: 'IBM Plex Sans' !important;
          text-align: center;

        }
        h4 {
          font-family: 'IBM Plex Sans' !important;
          font-size: 0.8rem;
          text-align: center;


        }
        h1 {
          font-family: 'Ubuntu' !important;
          position: absolute;
          bottom: 0px;
          text-align: right;
          width: 100%;
        }
        p {
          font-size: 0.8rem;
        }

        .price-tag {
          h1 {

          }

        }

      }
    }
  }
  .unit-select {
    overflow: auto;
    white-space: nowrap;
    .selected-unit {
      background-color: $casper-primary;
      h2 {
        color: $casper-white !important;

      }
    }
    .unit {
      display: inline-block;
      color: white;
      text-align: center;
      text-decoration: none;
      width: 120px;
      padding: 14px;
      margin-right: -2px;

      border-style: solid;
      border-width: 2px;
      border-color: $casper-primary;
      h2 {
        margin-bottom: 0px; 
      }


    }

  }

  .yourplan-card {
    margin-left: auto;
    margin-right: auto;
    border: solid 4px $casper-secondary;
    width: 40%;

    .yourplan-card-title {
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: $casper-secondary;
      color: $casper-black;

      h3{
        text-align: center;
        font-weight: 300 !important;
        font-size: 20px;



      }
    }
    .yourplan-card-body {
      text-align: left;
      padding: 8px;
      h3 {
        font-weight: 300;
      }
      h1 {
        text-align: right;
        
      }
    }
  }




  .day-select-section {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    font-family: 'IBM Plex Sans';

    .day-select-cards {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .day-option {
        border-style: solid;
        display: flex;
        justify-content: space-between;
        border-width: 1px;
        border-radius: 10px;
        padding: 15px;
        margin: 10px;
        min-width: 200px;
        width: 200px;
        
        h2 {
          color: $casper-black !important;
          margin-bottom: 0px;
          margin-top: auto;
          margin-bottom: auto;
          font-weight: 400;
        }
        h3 {
          color: $casper-primary;
          margin-bottom: 0px;
          text-align: right;


        }

      }
    }
  }


}

.question-section {

  .info {
    margin-left: auto;
    margin-right: auto;
    width: 30%;
  }
  .user-info {
    display: flex;
    flex-wrap: wrap;

    .input-item {
      width: 100%;
      margin-top: 10px;
    }
    input, label {
      display: block;
    }

    input {
      width: 100% !important;
      padding: 10px;
    }
  }
  .address-container {
    margin-left: 30px;
    margin-right: 30px;
 
        .address-input {
          width: 100%;
          padding: 10px;
        
      }
    
  }
}
