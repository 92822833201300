@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import './onboarding.scss';

/*
Colors
*/
$storable-primary: #73af7e;
$storable-secondary: #A2D1AB;
$storable-black: #3B3A3A;
$storable-white: #F7F7F7;
$storable-hover: #73af7ee9;



/*************/
/* Site Wide */
/*************/

body {
  background-color: #f7f7f7 !important;
  font-family: "Ubuntu" !important;
}

h1 {
  font-family: "Regular" !important;
  font-size: 3rem;
  color: $storable-primary;
  text-align: center;
  margin-bottom: 0px;
}

h2 {
  color: $storable-primary !important;
  font-size: 1rem !important;
}


.checkout-list {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-color: white;
  margin-top: 2rem;
  .checkout-list-item {
    display: flex;
    margin: 1rem;
    padding: 1rem;
    .checkout-list-text {
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
      margin-right: auto;
    }
    }
    img {
      width: 20rem;
      border-radius: 10px;
    }
  
    .checkout-list-controls {
      display: flex;
      justify-content: center;
      button {
        background-color: $storable-primary;
        border: none;
        padding: 5px;
        margin:5px;
      }
      .storable-btn-secondary {
        background: $storable-secondary !important;
        a {
          color: $storable-white;
        }
      }
  
  }
}





/* Onboarding Form */
.casper-onboard-page {
  height: 100%;
  .branding {
    text-align: center;
    margin-top: 20px;
    img {
      height: 150px;
    }
  }

  .form-content {
    display: flex;
    width: 50vw;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    .address-container {
      max-width: 100%;
      label {
        display: block;
      }
    }
    input {
      padding: 12px;
      border-radius: 8px;
      border-color: rgb(237, 236, 236) !important;
      border: solid 1px;
    }
    .date-select {
      input {
        width: 70%;
        margin: 0px;
      }
      select {
        padding: 15px;
        border-radius: 8px;
        border-color: #e4e4e4 !important;
        width:30%;
        margin: 0px;
      }
      
    }

    .address-input {
      width: 500px;
      max-width: 100%;
    }
    .date-select-container {
      margin-top: 15px;

    }
  }

  .Casper-form-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 12vh;
    background-color: white;
    border-top: 8px solid $storable-primary;
    .controls {
      display: flex;
      margin-left: auto;
      margin-right: auto;
      width: 80%;
      height: 12vh;
      align-items: center;
      justify-content: end;
      button {
        width: 100px;
        margin-right: 10px;
        border-radius: 5px;
      }

    }

  }

  .form-toolbar {
    margin: 20px;
    display: flex;
    justify-content: center;
    .page {
      width: 100px;
      margin: 20px;
      .toolbar-dash {
        hr {
          background-color: grey;
          color: grey;
          height: 10px;
        }
      }
    }
  }
}
.plan-additions {
  margin-bottom: 12vh;
}
.confirm-container {
  display: flex;

  h1 {
    font-size: 2rem;
    text-align: left;
  }
  .plan {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dcdcdc;
    text-align: center;
    padding: 12px 30px 12px 30px;
    margin: 10px;
    border-radius: 8px;
    .price {
      h3 {
        margin: 0px;
        font-weight: 400;
        font-size: 15px;
      }
      h2 {
        margin-bottom: 0px;
      }
      p {
        font-size: 10px;
        margin: 0px;
        margin-left: 5px;
      }
    }
  }
}

.plan-container {
  display: flex;
  flex-wrap: wrap;
  .plan {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dcdcdc;
    text-align: center;
    padding: 12px 30px 12px 30px;
    margin: 10px;
    border-radius: 8px;
    .price {
      h3 {
        margin: 0px;
        font-weight: 400;
        font-size: 15px;
      }
      h2 {
        margin-bottom: 0px;
      }
      p {
        font-size: 10px;
        margin: 0px;
        margin-left: 5px;
      }
    }
    h3 {
      font-size: 1rem;
      margin: 0px;
    }
  }
}

.create-acc-details {
  margin-left: 15px;
  margin-right: 15px;
  input {
    width: 100%;
  }
}


.booking-card {
  button {
    margin-top: 10px;
  }
}

.onboard-wrapper {
  background-image: url('../images/storable-background.svg');
  background-size: cover;   
}


.onboard-form {
  margin-top: 20px;
  height: 80%;
}



.casper-btn-secondary {
  background-color: $storable-secondary !important;

}

.storable-btn:hover {
  background-color: $storable-hover !important;
}


  .selected {
    border: solid 2px $casper-primary !important;
    border-radius: 6px 6px 6px 6px !important;
  }
  .selected-icon {
    display: relative !important;
  }
  .select-icon {
    display: none;
  }
  .check-mark {
    width: 20px;
    position: absolute;
    bottom: 20px;
    right: 10px;
    
  }
  .item-toolbar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    .input-group {
      margin-top:20px;
    }
    .item-select-radio {
      margin-left: 15px;
    }
  }
  /*modal*/
  .modal-dialog {
    .modal-body {
      display:flex;
      justify-content: space-around;
      align-items: center;

      .modal-item-title {
        justify-content: center;   
      }
      .storable-img-badge {
        width: 50% !important;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
        margin-top: 0;
        max-height: 50%;
      }
      .modal-img-container {
        width: 50%;
        img{
          border-radius: 10px;
        }
      }

    }
  }
  
  
  /* Welcome Banner */
.storable-welcome-banner {
    background-color: $storable-white;
    margin: 0;
    padding-top: 0.8rem;
    padding-bottom: 0.5rem;
    padding: 1rem;
    border-bottom: #3b3a3a10 solid;
    h5 {
        font-family: "IBM Plex Sans" !important;
        font-weight: 400;
    }  
}


/* Onboarding */
.page-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.onboard-form-container {
  h1 {
    font-family: 'Ubuntu', sans-serif !important;
    margin-bottom: 10px;
  }
}
.storable-onboard-card {
  margin-top:auto;
  height: 100vh;
  margin-bottom:auto;
}

  .form-btn-main {
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $storable-primary;
    border: none;
    color: $storable-white;
    margin-right: 10px;
    margin-bottom: 15px;
  }

.onboard-address-container {
  display:flex;
  align-items: center;
  justify-content: center;
  .address-form {
    width: 30rem;
  }
}

.address-form {
  div {
    margin-top: 10px;
  }
}

.onboard-form {
  width:100%;
}


.casper-hero-container {
  width: 45%;
  text-align: center;
  .casper-hero-img {
    width: 80%;

  }
  h1 {
    color: #757776;
    font-family: 'PT Sans', sans-serif !important;
    margin-top: 15px;
    font-size: 3rem;
      .span {
        color: $storable-primary;
        font-weight: bold;
      }
    }
}


/* End of onboarding */

/* user details */
.userdetails {
    justify-content: center;
    margin-top: 1rem;
      h2 {
          text-align: center;
          color: $storable-black !important;
          margin-bottom: 1.5rem;
      }
    div {
        width: 32rem;
        .storable-btn {
            background-color: $storable-primary !important;
            color: $storable-white;
            border: none;
            width: 100%;
            height: 3rem;
        }
        storable-btn:hover {
            background-color: $storable-secondary !important;
        }
    }
}

.onboarded-havingfun-container {
  display: flex;
  justify-content: center;
}
.onboarded-havingfun {
  text-align: center;
  h1 {
    margin-top: 15px;
  }
  img {
    margin-top: 25px;
  }
  p {
    margin-top: 25px;
    margin-bottom: 25px;
  }

}


/* Inventory */
.storable-inventory {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 100px;
    h3 {
        font-family: "IBM Plex Sans" !important;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
        font-weight: 400;
    }
    
      .storable-card {
        border-radius: 4px;
        border: none;
        width: 250px;
        margin: 10px;
        justify-content: left;
        box-shadow: 0px 0px 17px -2px rgba(0,0,0,0.19);
        -webkit-box-shadow: 0px 0px 17px -2px rgba(0,0,0,0.19);
        -moz-box-shadow: 0px 0px 17px -2px rgba(0,0,0,0.19);
        
        .card-body p {
            margin-bottom: 0px;
        }

        .storable-img-container {
            position: relative;
            border-radius: 4px;
            border: none;

            .storable-card-img {
                border: none;
            }
//storable item badge
            .storable-img-badge {
                position: absolute;
                top: 10px;
                left: 10px;
                background-color: green;
                color: $storable-white;
                padding: 2px 10px;
                border-radius: 3px;
            }

            .unknown {
              background-color: #dc3545;
            }

            .en-route {
              background-color: #ffc107;
            }

            .stored {
              background-color: #7DB48C;
            }

            .pending {
              background-color: $storable-secondary;
            }

            .returned {
              background-color: #17a2b8;
            }

            p {
                font-family: "IBM Plex Sans" !important;
                margin-bottom: 0px;
            }
    
        }

    }    
}

.storable-img-badge {
  top: 10px;
  left: 10px;
  color: $storable-white;
  padding: 2px 10px;
  border-radius: 3px;
  text-align: center;
}

.unknown {
background-color: #dc3545;
}

.en-route {
background-color: #ffc107;
}

.stored {
background-color: $casper-primary !important;
}

.pending {
background-color: $storable-secondary;
}

.returned {
background-color: #17a2b8;
}

p {
  font-family: "IBM Plex Sans" !important;
  margin-bottom: 0px;
}
.storable-background-wrapper {
  // background-image: url('../images/storable-large.svg');
  // background-size:cover;
  background-color: $casper-primary;
  height: 100vh;
}
/* Storable Login */

.storable-login-container {
      
  .storable-brand-sm-heading {
    color: $storable-primary;
    text-align: center;
    margin-bottom: 1rem;
  }
  .email-input {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    .form-floating {
      margin: 0.4rem;
    }
  }

  .storable-login-card {
      border: none !important;
      padding: 4rem;
      width: 25rem;
      height: fit-content;
      -webkit-box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.16);
      box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.16);
      h2 {
        color: $casper-darkblue !important;
      }

      .storable-login-btn {
        width: 100%;
        background-color: $casper-primary !important;
        border-color: $casper-primary !important;
      }
      .storable-login-btn:hover {
        width: 100%;
        background-color: $storable-primary !important;
        border-color: $storable-secondary !important;
      }
      .storable-login-tag {
        margin-top: 1rem;
        margin-bottom: 0;
        font-size: 0.8rem;
        color: $casper-darkblue;
      }

  }
}

  .booking-card {
    border: none !important;
    padding: 2rem 2rem 1rem 2rem;
    width: auto;
    height: fit-content;
    -webkit-box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.16);
    text-align: center;
    background-color: #ffffff;
    .booking-header {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    .storable-btn {
      margin-bottom: 1rem;
    }
  }



.account-div {
  td {
    width: auto;
    text-align: left;
    padding-left: 1rem;
  }
}

.badge-small {
  width: 50% !important;
}


@media screen and (max-width: 990px ) {
.form-content {
  input {
  }
}
  
/* Onboarding */
.onboard-form-container {
  display: block;
  margin-top: 20px;



}



.onboard-address-container {
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
  .address-form {
    width: 100%;
    div {
      margin-top: 10px;
    }
  }
}





.casper-hero-container {
  width: 100%;
  text-align: center;

  h1 {
    color: #757776;
    font-family: 'PT Sans', sans-serif !important;
    margin-top: 15px;
    .span {
      color: $storable-primary;
      font-weight: bold;
    }
    }
}

  .modal-dialog {
    .modal-body {
      display:inline;
      text-align: center;
      .modal-item-title {
        display: inline;
        text-align: center;
      

      }
      .storable-img-badge {
        width: 50% !important;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
      }
      .modal-img-container {
        margin-left: auto;
        margin-right: auto;
        width: 60%;
      }
      

    }
  }



  .storable-inventory {
    width: 100%;
    --bs-gutter-x: 0rem;
  }
  .container {
    width: 100%;
  //  margin: 0;
//    padding: 0;
  }
  .storable-login-card {
    width:auto;
  }

  .storable-card {
    width: 150px !important;
    margin: 0px;
  }
  .storable-nav {
    display: flex;
    .navbar-brand {
        img {
        width: 200px;
      }
    }
  }
}

.pac-item {
  padding: 10px;
}





.casper-primary-button {
  background-color: $storable-primary;
  color: $storable-white;
}

.casper-secondary-button {
  background-color: $storable-white;
  color: $storable-primary;
}


@media screen and (max-width: 600px) {
  .form-toolbar {
    margin-left: 10px;
    margin-right: 10px;
  }
  .confirm-container {
    margin-bottom: 12vh;
    margin-left: 15px;
    margin-right: 15px;
    display: block;

    .details {
      h1 {
        margin-bottom: 15px;
      }
      p {
        margin-bottom: 15px;
      }

    }
    .plan-details {
      margin-top: 10px;
    }
  }
  .casper-onboard-page {

    .form-content {
      width: 100%;
    }
    .address-input {
      width: 100% !important;
    }
    .date-select-container {
      input {
        width: 100% !important;
      }
      select {
        width: 100% !important;
      }
    } 
  }
 
  .item-toolbar {
    display: inline;
    align-items: center;
    .input-group {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
    .item-select-radio {
      display: flex;
      justify-content: center;
    }
  }
}