.storable-nav {
    //background-color: $storable-primary !important;
    font-family: "IBM Plex Sans" !important;
    //background-image: url('../images/storable-background.svg');
    background-color: $casper-primary !important;
    //background-size: cover;
    .storable-btn {
        background-color: $casper-white !important;
        color: $casper-primary;
        font-weight: 500;
        border: none;
    }
      .storable-btn:hover {
        background-color: $casper-lightgrey!important;
    }
      .storable-btn:active {
        color: $casper-primary;
    }
      .storable-dropdown-divider {
        background-color: #e6e4e4 !important;
        pointer-events: none;
    }

      .storable-dropdown-divider:hover {
        background-color: #e6e4e4 !important;
        pointer-events: none;
    }
    .navbar-brand h1 {
        color: $storable-white;
        margin-top: 0.8rem;
        padding-bottom: 1rem;
    }
    .navbar-brand img {
      width:14rem; 
    }
    
      h1 {
        font-family: "Regular" !important;
    }
  }