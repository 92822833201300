/*
Colors
*/
$storable-primary: #73af7e;
$storable-secondary: #A2D1AB;
$storable-black: #3B3A3A;
$storable-white: #F7F7F7;
$storable-hover: #73af7ee9;

$casper-primary: #5EB1BF;
$casper-secondary: #CBEAF0;
$casper-white: #FDFDFD;
$casper-black: #484848;
$casper-darkblue: #4A97A4;
$casper-lightgrey: #cccccc;
