@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@font-face {
  font-family: "Regular";
  /*Can be any text*/
  src: local("allroundgothic-demi"), url("../fonts/AllRoundGothic-Demi.ttf") format("truetype");
}


@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@import './_onboarding.scss';
@import './components/Navbar.scss';
@import './storablestyle.scss';
@import './components/CheckoutSection.scss';

/* Buttons */
.storable-btn {
  background-color: $casper-white !important;
  color: $casper-primary;
  width: 100%;
  height: 3rem;
  padding: 10px;
  border: none;
  width: 120px;
  border-radius: 10px;
  box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.18);
  -webkit-box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.18);
  -moz-box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.18);

  
}

.casper-btn-bs {
    width: 100%;
    background-color: #5EB1BF !important;
    border-color: #5EB1BF !important;
    max-width: 40%;
}


.logo img{
  width: 30%;
}
 


.casper-btn-secondary {
  background-color: $casper-white !important;

}

.storable-btn:hover {
  background-color: $casper-secondary !important;
  color: $casper-white !important;
}






.input-field {

}