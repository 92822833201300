.checkout-section {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $casper-primary;
    text-align: center;
    padding: 10px;
  
    button { 
      padding: 20px;
      height: auto;
      width: auto;
  
    }
  }
  